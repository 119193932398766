.Dropzone {
  height: 200px;
  width: 100%;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;

  .Highlight {
    background-color: rgb(188, 185, 236);
  }

  .Icon {
    opacity: 0.3;
    height: 64px;
    width: 64px;
    font-size: 64px;
    color: #1890ff;
  }

  .FileInput {
    display: none;
  }

}
