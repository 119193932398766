.flex {
  display: flex;
  flex-grow: 1;
}

.row {
  flex-direction: row;
}

.justify-content-center {
  justify-content: center;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.containerPadding {
  padding: 12px 24px;
}
