.ProgressBar {
  width: 100%;
  height: 12px;
  background-color: rgba(149, 22, 23, 0.16);
  border-radius: 5px;
.Progress {
  background-color: rgba(149, 22, 23, 1);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

}
