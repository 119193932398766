.header-print {
    background: #15125d;
    color: #fff;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    line-height: 28px;
    position: relative;
  }
.header-print:after
  {
    content: attr(data-count);
    position: absolute;
    top: -8px;
    right: -8px;
    background: #fff;
    border: 1px solid #15125d;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    line-height: 13px;
    font-size: 8px;
    color: #15125d;
  }
