@import "layout";

.ant-layout {
  min-height: 100vh;
}

.mainpage-order-card {
  border-color: $yellow;
  margin-right: 40px !important;
  min-width: 300px;
  margin-bottom: 40px;
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 25%;

  .ant-card-head {
    background-color: $blue;

    .ant-typography {
      color: white;
    }
  }

  &.mainpage-order-card-finished .ant-card-head {
    background-color: $blue;
  }
  &.mainpage-order-card-today .ant-card-head {
    background-color: rgb(21, 17, 93);
  }
  &.mainpage-order-card-unpaid .ant-card-head {
    background-color: rgb(143, 11, 13);
  }


  .ant-card-actions {
    width: 100%;
  }
}

.card-flex {
  .ant-card-head-wrapper {
    width: 100%;
  }
}

.description-label {
  padding: 16px 24px;
}
.product-list-sort {
  max-width: 480px;
  margin: 20px 0;
}

.product-list-img {
  max-width: 120px;
  height: auto;
}
.centered-image {
  max-width: 300px;
  height: auto;
  margin: 0 auto;
  > img {
    width: 100%;
    height: auto;
  }
}
.buttons {
  min-width: 124px;
  justify-content: space-between;
}

@media print {
  .ant-layout-content {
    z-index: 10000;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 !important;
    min-height: 100vh;
    .buttons {
      display: none !important;
    }
  }
  button.print {
    display: none !important;
  }
}
.ant-table-wrapper.no-highlight {
  .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
  .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
  .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
  .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: unset; //Change the existing color to `unset`
  }
}

.order-list-status {
  display: block;
  color: $white;
  padding: 2px 12px;
  &.order-list-status-vandaag {
    background-color: rgb(21, 17, 93);
  }
  &.order-list-status-later{
    background-color: rgb(255, 185, 125);
  }
  &.order-list-status-klaar {
    background-color: $blue;
  }
}

@media print {
  body > * {
    display: none;
  }

  body > #print-container {
    display: block;
    position: static;
    width: 100vw;
    height: 100vh;
  }

  @page {
    size: auto;
    margin: 0;
  }
}
